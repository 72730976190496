import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import React, { memo } from 'react'
import LazyLoad from 'react-lazyload'
import Fade from 'react-reveal/Fade'

export interface Props {
  cta?: ButtonProps[]
  description?: string
  image?: { [key: string]: any }
  title?: string
  subtitle?: string
}

export const Intro = memo(function Intro({
  cta,
  description,
  image,
  title,
  subtitle,
}: Props) {
  return (
    <Container dial={2} row tag="section" wrap>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <LeftSide className={image ? 'with-image' : ''}>
          <Media lessThan="ipadVertical">
            {image ? (
              <Fade distance="3.75rem" bottom>
                <LazyLoad>
                  <Image
                    src={image.src}
                    width={image.width}
                    height={image.height}
                    alt={image.alt}
                  />
                </LazyLoad>
              </Fade>
            ) : null}
          </Media>
          {title ? (
            <Fade bottom>
              <Title>{title}</Title>
            </Fade>
          ) : null}
          {subtitle ? (
            <Fade distance="3.75rem" bottom>
              <Subtitle>{subtitle}</Subtitle>
            </Fade>
          ) : null}
          <Media lessThan="ipadVertical">
            {description ? (
              <Fade bottom>
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </Fade>
            ) : null}
          </Media>
          {cta
            ? cta.map((item, index) => (
                <Fade bottom key={index}>
                  <Button {...item} variant="simple" />
                </Fade>
              ))
            : null}
        </LeftSide>
        <RightSide>
          <Media greaterThanOrEqual="ipadVertical">
            {image ? (
              <Fade distance="3.75rem" bottom>
                <LazyLoad>
                  <Image
                    src={image.src}
                    width={image.width}
                    height={image.height}
                    alt={image.alt}
                  />
                </LazyLoad>
              </Fade>
            ) : null}
            {description ? (
              <Fade bottom>
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </Fade>
            ) : null}
          </Media>
        </RightSide>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled(FlexBox)`
  max-width: 72.125rem;
  margin: 9.5rem auto 6rem;
  padding: 0 1.5rem;

  @media (max-width: 1023px) {
    margin: 7.5rem auto 3.75rem;
  }

  @media (max-width: 767px) {
    margin-top: 6rem;
  }
`

const LeftSide = styled.div`
  width: 45%;
  padding-right: 5.75vw;
  text-align: right;
  &.with-image {
    padding-top: 4.875rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding-right: 0;
    text-align: left;
    &.with-image {
      padding-top: 0;
    }
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3.375rem;
  margin-bottom: 1.125rem;
`

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.8px;
  line-height: 1.375rem;
  text-transform: uppercase;
`

const RightSide = styled.div`
  width: 55%;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 3rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;

  @media (max-width: 767px) {
    margin-top: 2.25rem;
  }
`
